const routes = [
  {
    path: '#',
    name:'cursos',

  },
  {
    path: '/cursos-corona',
    name: 'cursos_corona',
    requiresAuth: false,
    component: () => import('@/views/cursos/Cursos'),
    meta: {
      pageTitle: 'cursos',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Listado de cursos',
          active: true,
        },
      ],
    },
  },
]
export default routes
