import ApiService from '@/webServices/api.service'

export default {
    namespaced: true,
    state: {
        dataPorciento: '',
        dataSuscripciones: '',
        suscripcion: '',

    },
    getters: {

    },
    mutations: {

        setDataPorciento(state, value) {
            state.dataPorciento = value
        },
        setSuscripcion(state, value) {
            state.suscripcion = value
        },
        setDataSuscripciones(state, value) {
            state.dataSuscripciones = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setLoadingCorona(state, value) {
            state.loadingCorona = value
        },
    },
    actions: {

        async getPorcientoAprobado(context, data) {
            context.commit('setLoadingCorona', true)
            const url = 'dash/corona/alumno/porciento-aprobado?course_normative=1'
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setDataPorciento', response.data)
                    context.commit('setLoadingCorona', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoadingCorona', false)
                })
        },
        async getSuscripcionesCursosNormativos(context, data) {
            context.commit('setLoadingCorona', true)
            const url = 'dash/corona/alumno/suscripciones-cursos-normativos'
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setDataSuscripciones', response.data)
                    context.commit('setLoadingCorona', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoadingCorona', false)
                })
        },
        async getSuscripcion(context, data) {
            context.commit('setLoadingCorona', true)
            const url = 'dash/corona/filtro/informacion-curso-normativo'
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setSuscripcion', response.data)
                    context.commit('setLoadingCorona', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoadingCorona', false)
                })
        },
    },
}
