const routes = [
  {
    path: '#',
    name: 'consolidados ',

  },
  {
    path: '/consolidado-general   ',
    name: 'consolidado_general',
    requiresAuth: false,
    component: () => import('@/views/consolidado/Consolidado'),
    meta: {
      pageTitle: 'Consolidado',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'General',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consolidado-agencias',
    name: 'consolidado_agencias',
    requiresAuth: false,
    component: () => import('@/views/rankingAgencia/RankingAgencia'),
    meta: {
      pageTitle: 'Consolidado',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Ranking de Agencias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consolidado-corona',
    name: 'consolidado_corona',
    requiresAuth: false,
    component: () => import('@/views/consolidadoCorona/ConsolidadoGeneral'),
    meta: {
      pageTitle: 'Consolidado',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Consolidado de Corona',
          active: true,
        },
      ],
    },
  },
]
export default routes
