import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BTab, BTabs, BCard, BFormRadioGroup, BFormCheckboxGroup, BOverlay,
} from 'bootstrap-vue'
import AutocompleteInfinity from '@/components/AutocompleteInfinity.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('flat-pickr', flatPickr)
Vue.component('v-select', vSelect)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('b-overlay', BOverlay)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-button', BButton)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-tab', BTab)
Vue.component('b-tabs', BTabs)
Vue.component('b-card', BCard)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('autocomplete-infinity', AutocompleteInfinity)