const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    requiresAuth: false,
    component: () => import('@/views/dashboard/Dashboard'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-cursos',
    name: 'dashboard_cursos',
    requiresAuth: false,
    component: () => import('@/views/dashboardCorona/Dashboard'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },{
    path: '/dashboard-carozzi',
    name: 'dashboard_carozzi',
    requiresAuth: false,
    component: () => import('@/views/dashboardCarozzi/Dashboard'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-mutual',
    name: 'dashboard_mutual',
    requiresAuth: false,
    component: () => import('@/views/dashboardMutual/Dashboard'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },

]
export default routes
