<template>
  <v-select
      :options="dataValue"
      :filterable="true"
      :eager="true"
      :label="label"
      :placeholder="placeholder"
      @search="onSearch"
      @input="selectItemEvent"
      @close="onClose"
      @open="onOpen"
      :value="selectedValue"
  >
    <template #list-footer>
      <li
          v-show="hasNextPageShow"
          ref="load"
          class="loader"
      >
        <div class="d-flex align-items-center">
          <strong>Cargando  más...</strong>
          <b-spinner
              small
              variant="primary"
              class="ml-auto"
          />
        </div>
      </li>
    </template>
    <template slot="no-options">
      <div class="d-flex align-items-center">
        <strong>Cargando...</strong>
        <b-spinner
            variant="primary"
            class="ml-auto"
        />
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import { BSpinner } from 'bootstrap-vue'
import { url } from '@core/utils/validations/validations'

export default {
  name: 'AutocompleteInfinity',
  components: {
    vSelect,
    BSpinner,
  },
  props: ['url', 'placeholder', 'label', 'itemsPerPage', 'holding','selectedValue'],
  data: () => ({
    observer: null,
    list: [],
    page: 1,
    search: '',
    searchSend: false,
    enlace: '',
  }),
  mounted() {
    this.observer = new IntersectionObserver(this.loadMore)

  },
  computed: {
    totalRows() {
      return this.$store.state.filtrosReportes.rowsTotalSelect;
    },
    to() {
      return this.$store.state.filtrosReportes.toSelect;
    },
    lastPage() {
      return this.$store.state.filtrosReportes.lastPage;
    },
    value(){
      return this.$store.state.filtrosReportes.valueFilter;
    },
    hasNextPage() {
      return this.totalRows > this.to
    },
    hasNextPageShow() {
      return this.totalRows > this.to && this.value.length > 0
    },
    dataValue() {
      this.list = [];
      this.$store.state.filtrosReportes.valueFilter.length > 0 ? this.$store.state.filtrosReportes.valueFilter.forEach((item) => this.list.push(item)) : this.list= [];
      return this.list;
    }
  },
  watch: {

  },
  methods: {
    async onClose() {
      this.page=0;
      this.search=""
      this.list = [];
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
      this.searchSend = false
      this.$store.commit('filtrosReportes/setData', [])
      const param = {
        page: 1,
        search_string: this.search,
        item_per_page: this.itemsPerPage,
        url: this.url,
        id_holding: this.holding,
      }
      this.getValue(param)
    },

    selectItemEvent(option) {
      this.$emit('item-selected', option)
    },
    getValue:_.debounce(function (param) {
      this.$store.dispatch('filtrosReportes/getValue', param)
    }, 100),
    goToSearch(){
      if(this.search==''){
        const param = {
          page:  this.page,
          search_string: this.search,
          item_per_page: this.itemsPerPage,
          url: this.url,
          id_holding: this.holding,
        }
        this.getValue(param)
      }else
        this.onSearch(this.search)
    },
    onSearch: _.debounce(function (search) {
      if(this.search!=search){
        this.$store.commit('filtrosReportes/setData', [])
        this.search = search || ''
      }
      const param = {
        page: 1,
        search_string: this.search,
        item_per_page: this.itemsPerPage,
        url: this.url,
        id_holding: this.holding,
      }
      this.getValue(param)
    }, 800),

    async loadMore([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.page++
        this.goToSearch()
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
