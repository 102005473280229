export default {
  methods: {
    // Obtención de id/key para componentes
    $getId(componentName = 'component') {
      const min = Math.ceil(0)
      const max = Math.floor(1000)
      const val = Math.floor(Math.random() * (max - min)) + min // The maximum is exclusive and the minimum is inclusive
      const val2 = Math.floor(Math.random() * (max - min)) + min
      const elementName = `${componentName}-${val}-${val2}`
      return elementName
    },
    // Obtención de numero random entr dos números
    $randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

    // Obtención array de días entre dos fechas
    $getDates(startDate, stopDate) {
      let dates = []
      // to avoid modifying the original date
      const theDate = new Date(startDate)
      while (theDate < new Date(stopDate)) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
      }
      dates = [...dates, new Date(stopDate)]
      return dates
    },
    // Obtención de fecha antes o después de unos días
    $getDateLimit(date, days) {
      const limitDate = new Date(date)
      limitDate.setDate(limitDate.getDate() + days)
      return limitDate
    },
    // Obtener FormData de un objeto
    $getFormData(form = {}, method = null) {
      const keys = Object.keys(form)
      const formData = new FormData()
      if (method) form._method = method
      keys.forEach(k => {
        if (k != '_record') {
          if (form[k] == null) {
            // Valor nulo
            formData.append(k, '')
          } else if (typeof form[k] === 'object' && !form[k].dataURL) {
            // Valor tipo Objeto (no Imgáenes)
            formData.append(k, JSON.stringify(form[k]))
          } else {
            formData.append(k, form[k])
          }
        }
      })
      return formData
    },
    // Obtener número de teléfono
    $getPhoneNumber(phone) {
      if ((phone || []).length > 4) {
        const result = phone.replace(/\./g, ' ').replace(/\D+/g, '')
        return `+${result}`
      }
      return null
    },
  },
}
