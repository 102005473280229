import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    consolidadoAgencia: [],
    loading: false,
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
  },
  getters: {

  },
  mutations: {
    setConsolidadoAgencia(state, value) {
      state.consolidadoAgencia = value
    },
    setLastPage(state, value) {
      state.lastPage = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },
  },
  actions: {
    async getConsolidadoAgencia(context, data) {
      context.commit('setLoading', true)
      const url = `dash/carozzi/consolidado/ranking-agencia?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      return ApiService
        .get(url, data.filter)
        .then(response => {
          context.commit('setConsolidadoAgencia', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setTo', response.data.to)
          context.commit('setForm', response.data.from)
          context.commit('setLastPage', response.data.last_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
          // context.commit('setLoading', false)
        })
    },
    async exportData(context, data) {
      const url = `dash/carozzi/consolidado/ranking-agencia/export?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      context.commit('setLoading', true)
      return ApiService
        .postGetFile(url, data.filter, 'Ranking_Agencia.xlsx')
        .then(response => {
          context.commit('setLoading', false)
          console.log(response)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
  },
}
