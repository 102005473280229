import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Modules
import security from './modules/security'
import auth from './modules/auth'
import profile from './modules/profile'
import filtrosReportes from './modules/filtrosReportes'
import dashboard from './modules/dashboard'
import dashboardCarozzi from './modules/dashboardCarozzi'
import dashboardCorona from './modules/dashboardCorona'
import fichas from './modules/fichas'
import alumnos from './modules/alumnos'
import consolidado from './modules/consolidado'
import consolidadoAgencias from './modules/consolidadoAgencias'
import reportesAgencias from './modules/reportesAgencias'
import reportesAmpliado from './modules/reportesAmpliado'
import reportesSimplificado from './modules/reportesSimplificado'
import reporteCorona from './modules/reporteCorona'
import consolidadoCorona from './modules/consolidadoCorona'
import curso from './modules/curso'
import reportesMutualIntro from './modules/reportesMutualIntro'
import reportesMutualPractico from './modules/reportesMutualPractico'
import reportesMutualRol from './modules/reportesMutualRol'
import consolidadoGerencia from './modules/consolidadoGerencia'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    security,
    auth,
    verticalMenu,
    profile,
    filtrosReportes,
    dashboard,
    dashboardCorona,
    dashboardCarozzi,
    fichas,
    alumnos,
    consolidado,
    consolidadoAgencias,
    reportesAgencias,
    reporteCorona,
    consolidadoCorona,
    reportesSimplificado,
    curso,
    reportesAmpliado,
    reportesMutualIntro,
    reportesMutualPractico,
    reportesMutualRol,
    consolidadoGerencia
  },
  strict: process.env.DEV,
})
