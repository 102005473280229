import Vue from 'vue'
import httpStatus from '../httpStatus'
import utilities from './utilities'

const parseError = (error, locale = 'es') => {
  let object = null
  let status = null
  let message = null
  let code = null
  let errors = []
  if (error) {
    object = JSON.parse(JSON.stringify(error))
    object = object || {}
    if (error.response && error.response.data) {
      status = error.response.status ? error.response.status : status
      code = error.response.data.code
      errors = error.response.data.errors || []
      if (typeof error.response.data.error === 'string') {
        message = error.response.data.error
      } else if (typeof error.response.data.error === 'object' || typeof error.response.data.error === 'array') {
        errors = error.response.data.error || []
      }
    } else if (object.message == 'Error de conexión') {
      status = 600
    }
  }
  status = status || 400
  status = code || status
  if (!message) {
    if (httpStatus[locale][status]) {
      message = httpStatus[locale][status]
    } else if (object.message) {
      message = object.message
    } else if (error) {
      message = error
    }
  }
  message = message || 'Error desconocido'
  return {
    status,
    message,
    errors,
  }
}

const isRetrievable = error => {
  const status = [408, 600, 400]
  if (error) {
    return status.includes(error.status)
  }
  return false
}

Vue.mixin({
  methods: {
    ...utilities.methods,
    parseError,
    isRetrievable,
  },
})
