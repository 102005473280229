const routes = [
  {
    path: '/profile',
    name: 'profile',
    requiresAuth: false,
    component: () => import('@/views/profile/Profile'),
    meta: {
      pageTitle: 'Profile',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Account',
          active: true,
        },
      ],
    },
  },
]

export default routes
