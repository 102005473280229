import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        columns: [],
        holding: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        rowsTotalSelect: 0,
        exportData: [],
        loading:false,
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },

        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setColumns(state, value) {
            state.columns = value
        },
    },
    actions: {
        async getList(context, data) {
            context.commit('setLoading', true)
            var params = new URLSearchParams(data);
            const url = 'dash/mutual/consolidado/gerencia-porciento-avances?'+params.toString()
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setList', [])
                    context.commit('setTotal', 0)
                    context.commit('setForm', 0)
                    context.commit('setTo', 0)
                    context.commit('setCurrentPage', 0)
                    context.commit('setLoading', false)
                })
        },
        async exportData(context, data) {
            context.commit('setLoading', true)
            var params = new URLSearchParams(data)
            const url = 'dash/mutual/consolidado/gerencia-porciento-avances/export?'+params.toString();
            return ApiService
                .postGetFile(url, data.filter,"consolidadoAgencia.xlsx")
                .then(response => {
                    context.commit('setLoading', false)
                    console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
        async getColumns(context, data) {
            context.commit('setLoading', true)
            var params = new URLSearchParams(data);
            const url = 'dash/mutual/consolidado/gerencia-porciento-avances/columns?'+params.toString();
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setColumns', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setColumns', [])
                    context.commit('setLoading', false)
                })

        },
    },
}