import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    consolidadoArea: [],
    consolidadoSucursal: [],
    loadingArea: true,
    loadingSucursal: false,
    loadingNext: false,
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
  },
  getters: {

  },
  mutations: {
    setConsolidadoArea(state, value) {
      state.consolidadoArea = value
    },
    setConsolidadoSucursal(state, value) {
      state.consolidadoSucursal = value
    },
    setLastPage(state, value) {
      state.lastPage = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoadingArea(state, value) {
      state.loadingArea = value
    },
    setLoadingSucursal(state, value) {
      state.loadingSucursal = value
    },
  },
  actions: {
    async getConsolidadoArea(context, data) {
      context.commit('setLoadingArea', true)
      const url = `dash/consolidado/area?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      return ApiService
        .get(url, data.filter)
        .then(response => {
          context.commit('setConsolidadoArea', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setTo', response.data.to)
          context.commit('setForm', response.data.from)
          context.commit('setLastPage', response.data.last_page)
          context.commit('setLoadingArea', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoadingArea', false)
        })
    },
    async getConsolidadoSucursal(context, data) {
      context.commit('setLoadingSucursal', true)
      const url = `dash/consolidado/sucursal?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      return ApiService
        .get(url, data.filter)
        .then(response => {
          context.commit('setConsolidadoSucursal', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setTo', response.data.to)
          context.commit('setForm', response.data.from)
          context.commit('setLastPage', response.data.last_page)
          context.commit('setLoadingSucursal', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoadingSucursal', false)
          // context.commit('setLoading', false)
        })
    },
    async exportDataArea(context, data) {
      const url = `dash/consolidado/area/export?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      context.commit('setLoadingArea', true)
      return ApiService
        .postGetFile(url, data.filter, 'Consolidado_Area.xlsx')
        .then(response => {
          context.commit('setLoadingArea', false)
          console.log(response)
        })
        .catch(error => {
          context.commit('setLoadingArea', false)
          console.log(error)
        })
    },
    async exportDataSucursal(context, data) {
      const url = `dash/consolidado/sucursal/export?page=${data.page}&item_per_page=${data.perPage}&fecha_desde=${data.fechaDesde}&fecha_hasta=${data.fechaHasta}&orderBy=${data.sortBy}&mode_orderBy=${data.sortDirection}`
      context.commit('setLoadingSucursal', true)
      return ApiService
        .postGetFile(url, data.filter, 'Consolidado_Sucursal.xlsx')
        .then(response => {
          context.commit('setLoadingSucursal', false)
          console.log(response)
        })
        .catch(error => {
          context.commit('setLoadingSucursal', false)
          console.log(error)
        })
    },
  },
}
