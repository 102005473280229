// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    rowsTotalSelect: 0,
    toSelect: 0,
    lastPage: 0,
    valueFilter: [],
    list: [],
    holding: [],
    columns: [],
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    exportData: [],
    loading: true,
  },
  getters: {

  },
  mutations: {
    setData(state, value) {
      state.list = Object.values(value)
    },
    setTotalSelect(state, value) {
      state.rowsTotalSelect = value
    },
    setToSelect(state, value) {
      state.toSelect = value
    },
    setLastPage(state, value) {
      state.lastPage = value
    },
    setList(state, value) {
      state.list = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setFrom(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setColumns(state, value) {
      state.columns = value
    },
  },
  actions: {
    async getValue(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/listado'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setData', response.data.data)
          context.commit('setTotalSelect', response.data.total)
          context.commit('setToSelect', response.data.to)
          context.commit('setFrom', response.data.from)
          context.commit('setLastPage', response.data.last_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async exportData(context, data) {
      const url = 'dash/fichas/listado/export'
      context.commit('setLoading', true)
      return ApiService
          .postGetFile(url, data, 'Inscripciones.xlsx')
          .then(response => {
            context.commit('setLoading', false)
            console.log(response)
          })
          .catch(error => {
            context.commit('setLoading', false)
            console.log(error)
          })
    },
  },
}
