const routes = [
  {
    path: '/inscripciones',
    name: 'inscripciones',
    requiresAuth: false,
    component: () => import('@/views/fichas/Fichas'),
    meta: {
      pageTitle: 'Inscripciones',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Listado de Inscripciones',
          active: true,
        },
      ],
    },
  },
]
export default routes
