import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    aprobados: 0,
    reprobados: 0,
    noInscritos: 0,
    conEvaluacionMaxima: 0,
    conEvaluacionRegular: 0,
    consolidadoEstudiante: null,
    totalCursos: '',
    totalFichas: '',
    totalFichasActivas: '',
    fichasProximasCerrar: '',
    fichasProximasIniciar: '',
    totalAlumnos: '',
    totalAlumnosActivos: '',
    loading: false,
    resumenFicha: [],
    dataPorciento: '',
    dataSuscripciones: '',
  },
  getters: {

  },
  mutations: {
    setTotalCursos(state, value) {
      state.totalCursos = value
    },
    setTotalFichas(state, value) {
      state.totalFichas = value
    },
    setTotalFichasActivas(state, value) {
      state.totalFichasActivas = value
    },
    setFichasProximasCerrar(state, value) {
      state.fichasProximasCerrar = value
    },
    setFichasProximasIniciar(state, value) {
      state.fichasProximasIniciar = value
    },
    setTotalAlumnos(state, value) {
      state.totalAlumnos = value
    },
    setTotalAlumnosActivos(state, value) {
      state.totalAlumnosActivos = value
    },
    setResumenFicha(state, value) {
      state.resumenFicha = value
    },
    setDataPorciento(state, value) {
      state.dataPorciento = value
    },
    setDataSuscripciones(state, value) {
      state.dataSuscripciones = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setLoadingCorona(state, value) {
      state.loadingCorona = value
    },
  },
  actions: {
    async getTotalCursos(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/curso/total-activos'
      return ApiService
        .post(url, data)
        .then(response => {
          console.log(response.data.data);
          context.commit('setTotalCursos', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getTotalFichas(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/total'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setTotalFichas', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getTotalFichasActivas(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/total-activas'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setTotalFichasActivas', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getFichasProximasCerrar(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/proximas-cerrar'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setFichasProximasCerrar', response.data.total)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getFichasProximasIniciar(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/proximas-iniciar'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setFichasProximasIniciar', response.data.total)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getTotalAlumnos(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/alumno/total'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setTotalAlumnos', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getTotalAlumnosActivos(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/alumno/total-activos'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setTotalAlumnosActivos', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getResumenFicha(context, data) {
      context.commit('setLoading', true)
      const url = 'dash/fichas/resumen'
      return ApiService
        .post(url, data)
        .then(response => {
          context.commit('setResumenFicha', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
           context.commit('setLoading', false)
        })
    }
  },
}
