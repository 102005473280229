const routes = [
  {
    path: '#',
    name:'reportes',

  },
  {
    path: '/reportes-simplificado',
    name: 'reportes_simplificado',
    requiresAuth: false,
    component: () => import('@/views/reportesSimplificado/ReportesSimplificado'),
    meta: {
      pageTitle: 'Reportes',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Reportes Simplificado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reportes-ampliado',
    name: 'reportes_ampliado',
    requiresAuth: false,
    component: () => import('@/views/reportesAmpliado/ReportesAmpliado'),
    meta: {
      pageTitle: 'Reportes Ampliado',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Reportes Ampliado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reportes-corona',
    name: 'reportes_corona',
    requiresAuth: false,
    component: () => import('@/views/reportesSucursalArea/ReportesSucursalArea'),
    meta: {
      pageTitle: 'Reportes',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Reportes Corona',
          active: true,
        },
      ],
    },
  },{
    path: '/reportes-agencias',
    name: 'reportes_agencias',
    requiresAuth: false,
    component: () => import('@/views/reportesAgencias/ReportesAgencias'),
    meta: {
      pageTitle: 'Reportes',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Reportes Agencias',
          active: true,
        },
      ],
    },
  },
]

export default routes
