import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    consolidadoAlumnos: [],
    consolidado: [],
    loading: true,
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
  },
  getters: {

  },
  mutations: {
    setConsolidadoAlumnos(state, value) {
      state.consolidadoAlumnos = value
    },
    setConsolidado(state, value) {
      state.consolidado = value
    },
    setLastPage(state, value) {
      state.lastPage = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },
  },
  actions: {
    async getConsolidadoResumen(context, data) {
      context.commit('setLoading', true)
      const url = `dash/fichas/consolidado-resumen?page=${data.page}&item_per_page=${data.perPage}`
      return ApiService
        .post(url, data.filter)
        .then(response => {
          context.commit('setConsolidadoAlumnos', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setTo', response.data.to)
          context.commit('setForm', response.data.from)
          context.commit('setLastPage', response.data.last_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
          // context.commit('setLoading', false)
        })
    },
    async exportData(context, data) {
      const url = `dash/fichas/consolidado-resumen/export?page=${data.page}&item_per_page=${data.perPage}`
      context.commit('setLoading', true)
      return ApiService
          .postGetFile(url, data, 'Consolidado_Inscripciones.xlsx')
          .then(response => {
            context.commit('setLoading', false)
            console.log(response)
          })
          .catch(error => {
            context.commit('setLoading', false)
            console.log(error)
          })
    },
  },
}
