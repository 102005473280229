import Vue from 'vue'
import VueRouter from 'vue-router'

// Modules routes
import profile from './modules/profile'
import reportes from './modules/reportes'
import dashboard from './modules/dashboard'
import fichas from './modules/fichas'
import consolidado from './modules/consolidado'
import cursos from './modules/cursos'
import mutual from './modules/mutual';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    /*{
      path: '/dashboard',
      redirect: { name: 'dashboard' },
    },*/

    ...dashboard,
    ...profile,
    ...reportes,
    ...fichas,
    ...cursos,
    ...consolidado,
    ...mutual,
    {
      path: '/login',
      name: 'login',
      requiresAuth: false,
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      requiresAuth: false,
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'login',
    },
  ],
})

export default router
