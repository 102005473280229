// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
    namespaced: true,
    state: {
        rowsTotalSelect: 0,
        toSelect: 0,
        lastPage: 0,
        list: [],
        holding: [],
        columns: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        currentPage: 1,
        exportData: [],
        loading: false,
    },
    getters: {

    },
    mutations: {

        setTotalSelect(state, value) {
            state.rowsTotalSelect = value
        },
        setToSelect(state, value) {
            state.toSelect = value
        },
        setLastPage(state, value) {
            state.lastPage = value
        },
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setColumns(state, value) {
            state.columns = value
        },
    },
    actions: {

        async getList(context, data) {
             var params = new URLSearchParams(data);
            const url = 'dash/reporte-cliente-empresa/data?'+params.toString()
            context.commit('setLoading', true)
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async exportData(context, data) {
            //const url = `dash/reporte-cliente-empresa/export-all?num_ficha=${data.num_ficha}&num_orden_compra=${data.num_orden_compra}&fecha_desde=${data.fecha_desde}&fecha_hasta=${data.fecha_hasta}&id_aplicacion=${data.id_aplicacion}&page=${data.page}&perPage=${data.perPage}&active=${data.active}`
            const url = 'dash/reporte-cliente-empresa/export-all'
            context.commit('setLoading', true)
            return ApiService
                .postGetFile(url, data, 'Reporte_General.xlsx')
                .then(response => {
                    context.commit('setLoading', false)
                    console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
        async exportAllData(context, data) {
            const url = 'dash/reporte-cliente-empresa/export-all'
            context.commit('setLoading', true)
            return ApiService
                .post(url,  data)
                .then(response => {
                    context.commit('setLoading', false)
                    console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
        async getColumns(context, data) {
            context.commit('setLoading', true)
            var params = new URLSearchParams(data);
            const url = 'dash/reporte-cliente-empresa/columns?'+params.toString()
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setColumns', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setColumns', [])
                    context.commit('setLoading', false)
                })
        },
    },
}
